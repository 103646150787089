import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import axios from 'axios'

/*
authentication.js
Serviço de autenticação da Plataforma de Empresas.
Usa o React useContext para criar um contexto de autenticação.
Os valores passados para o contexto são:
    currentUser: nome do usuário autenticado
    login: função de login
    logout: função de logout
    token: token de autenticação
    history: histórico de navegação
Conexões externas:
    Nenhuma
Tutoriais relevantes:
    createContext: https://reactjs.org/docs/context.html
    useContext: https://react.dev/reference/react/useContext
    useEffect: https://react.dev/reference/react/useEffect
    useState: https://react.dev/reference/react/useState
    useHistory: https://v5.reactrouter.com/web/api/history
    axios: https://axios-http.com/docs/intro
    localStorage: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
*/

// Criação do contexto de autenticação
const AuthContext = React.createContext();

// Hook para uso do contexto de autenticação. Usado no componente Login.js
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    // Hooks
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState('');
    const [token, setToken] = useState('');

    const history = useHistory();

    useEffect(() => {
        setToken(localStorage.getItem('token'));
        setCurrentUser(localStorage.getItem('currentUser'));
        axios.defaults.headers['x-access-token'] = localStorage.getItem('token');
        setLoading(false);
    }, [])

    useEffect(() => {
        localStorage.setItem('token', token);
        localStorage.setItem('currentUser', currentUser);

        if (token) {
            checkToken(token)
                .then(res => {
                    if (!res) {
                        setToken('');
                        setCurrentUser('');
                    } else {
                        axios.defaults.headers['x-access-token'] = token;
                    }
                })
        }

    }, [token, currentUser])

    // Função de login. Retorna true se o login foi bem sucedido, false caso contrário.
    async function login(email, password) {
        try {
            /* realiza o login usando os campos de email e senha. a conf é uma constante que está no arquivo .env
             * TODO: realizar o login sem a necessidade de passar a conf e adicionar um componente para selecionar a conf.
             */

            let response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth2/pe/recruiter/login`,
                {
                    email,
                    password
                },
            )
            axios.defaults.headers['x-access-token'] = response.data.token;
            setToken(response.data.token);
            setCurrentUser(response.data.recruiter_name);
            return true;
        }
        catch (error) {
            logout();
            console.log(error) // TODO improve error handling
            return false;
        }
    }

    // Função de logout. Limpa o token e o usuário atual.
    async function logout() {
        setToken('');
        setCurrentUser('');
        localStorage.clear();
    }

    // Comunica com a API para verificar se o token é válido.
    async function checkToken(token) {
        try {
            let response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth2/verify`, {}, {
                headers: {
                    'x-access-token': token
                },
            })
            return response.status === 200;
        }
        catch (error) {
            logout();
            console.log(error) // TODO improve error handling
            return false;
        }
    }

    // Funções e variáveis disponíveis para uso em outros componentes
    const value = {
        currentUser,
        login,
        logout,
        token,
        history,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
