import axios from 'axios';

/*
comments.js
Serviço de comentários da Plataforma de Empresas.
Possibilita a criação, edição, exclusão e leitura de comentários.
Conexões externas:
    Nenhuma
Tutoriais relevantes:
    axios: https://axios-http.com/docs/intro
*/

/*
Cria um comentário para um candidato.
Parâmetros:
    candidateId: id do candidato
    comment: texto do comentário
*/
export async function createComment(candidateId, comment) {
    try {
        let response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/comments/${candidateId}`,
            {
                comment,
            }
        );

        return response.data;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

/*
Edita um comentário.
Parâmetros:
    commentId: id do comentário
    comment: texto do comentário
*/
export async function editComment(commentId, comment) {
    try {
        let response = await axios.put(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/comments/${commentId}`,
            {
                comment,
            }
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

/*
Deleta um comentário.
Parâmetros:
    commentId: id do comentário
*/
export async function deleteComment(commentId) {
    try {
        let response = await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/comments/${commentId}`, {},
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

/*
Retorna os comentários de um candidato.
Parâmetros:
    candidateId: id do candidato
*/
export async function getComments(candidateId) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/comments/${candidateId}`, {}
        );

        return response.data;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return [];
    }
}
