import axios from 'axios';

/*
filters.js
Serviço de filtros da Plataforma de Empresas.
Possibilita a criação, edição, exclusão e leitura de filtros.
Conexões externas:
    Utilizado em src/context/filterContext.js
Tutoriais relevantes:
    axios: https://axios-http.com/docs/intro
*/

/**
 * Lista os filtros da empresa. O token de autenticação é passado no header.
 */
export async function getFilters() {
    let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/filters`, {});
    return response.data;
}

/**
 * Cria um filtro.
 * @param {String} token token de autenticação
 * @param {Object} filter objeto com os dados do filtro
 */
export async function createFilter(token, filter) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/filters`,
            {
                name: filter.name,
                criteria: filter.criteria
            },
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}
/**
 * Deleta um filtro.
 * @param {String} token token de autenticação
 * @param {Object} filter objeto com os dados do filtro
 */
export async function deleteFilter(token, filter) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/filters/${filter.name}`, {},
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

/**
 * Edita um filtro.
 * @param {String} token token de autenticação
 * @param {Object} filter objeto com os dados do filtro
 */
export async function editFilter(token, filter) {
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/filters/${filter.name}`,
            {
                criteria: filter.criteria
            },
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}
