import React from 'react';
import { useAuth } from '../services/authentication';
import axios from 'axios';

const ConferenceContext = React.createContext(
  {
    conferences: []
  }
);

const ConferenceContextProvider = ({ children }) => {
  const [conferences, setConferences] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const auth = useAuth();

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/conferences`, {
      headers: { 'x-access-token': auth.token }
    })
      .then(res => setConferences(res.data.conferences.map(c => ({ value: c.id, label: c.name }))))
      .catch(err => console.log(err));
    setIsLoading(false);
  }, [auth.token]);

  return (
    <ConferenceContext.Provider value={{ conferences }}>
      {!isLoading && children}
    </ConferenceContext.Provider>
  );
}

export default ConferenceContext
export { ConferenceContextProvider };