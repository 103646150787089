import axios from 'axios';

/*
labels.js
Serviço de labels da Plataforma de Empresas.
Possibilita a criação, edição, exclusão e leitura de labels.
Conexões externas:
    Usado em src/components/CandidateList.js
Tutoriais relevantes:
    axios: https://axios-http.com/docs/intro
*/

/**
 * Cria uma label para um candidato.
 * Em caso de erro, retorna false.
 * @param {number} candidateId - id do candidato
 * @param {string} label - nome da label
 */
export async function createLabel(candidateId, label) {
    try {
        let response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/labels/${candidateId}`,
            {
                name: label,
            }
        );

        return response.data;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/**
 * Exclui uma label.
 * Em caso de erro, retorna false.
 * @param {number} labelId - id da label
 */
export async function deleteLabel(labelId) {
    try {
        let response = await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/labels/${labelId}`, {}
        );

        return response.status;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

/**
 * Lista as labels de um candidato.
 * Em caso de erro, retorna uma lista vazia.
 * @param {number} candidateId - id do candidato
 */
export async function getLabels(candidateId) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/labels/${candidateId}`, {}
        );
        return response.data;
    }
    catch (error) {
        console.log(error);
        return [];
    }
}

/**
 * Lista todas as labels.
 * Em caso de erro, retorna uma lista vazia.
 */
export async function getAllLabels() {
    try {
        let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/labels`, {}
        );
        return response.data;
    }
    catch (error) {
        console.log(error);
        return [];
    }
}