import axios from 'axios';
/*
favorites.js
Serviço de favoritos da Plataforma de Empresas.
Possibilita a criação, exclusão e leitura de favoritos.
Conexões externas:
    Nenhuma
Tutoriais relevantes:  
    axios: https://axios-http.com/docs/intro
*/

function addConferencesToQuery(query, conferences) {
    if (conferences.length > 0) {
        conferences.forEach(conference => { query += `conference=${conference.value}&` })
    }
    query = query.slice(0, -1);
    return query;
}

/**
 * Lista os favoritos da empresa.
 * Em caso de erro, retorna uma lista vazia.
 * @param {number} page - número da página
 */
export async function getFavorites(page, conferences) {
    let query = addConferencesToQuery('', conferences);
    try {
        let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/favorites?page=${page}&${query}`, {});
        return response.data;
    }
    catch (error) {
        return [];
    }
}

/**
 * Deleta um favorito.
 * @param {number} candidateId - id do candidato
 */
export async function deleteFavorite(candidateId) {
    try {
        await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/favorites/${candidateId}`, {});
        return true;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

/**
 * Adiciona um favorito.
 * @param {number} candidateId - id do candidato
 */
export async function addFavorite(candidateId) {
    try {
        await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/favorites/${candidateId}`, {});
        return true;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

