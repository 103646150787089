import React, {useState} from "react";
import logo from '../images/brasa.png';
import '../styles/Nav.css';
import '../App.css';
import {useHistory, Link} from "react-router-dom";
import {useAuth} from "../services/authentication";
import Tooltip from '@material-ui/core/Tooltip';

/*
Nav.js
Barra de navegação da Plataforma de Empresas.
Conexões externas:
    recurso: logo em "../images/brasa.png"
    stylesheet: "../styles/Nav.css" e bootstrap
    autenticação: usa função useAuth de "../services/authentication"
Tutoriais relevantes:
    useHistory: https://v5.reactrouter.com/web/api/history
    Boostrap: https://getbootstrap.com/docs/4.1/getting-started/introduction/
    Tooltip: https://mui.com/components/tooltips/
 */
 
function Nav() {
    // Hooks
    const [status, setStatus] = useState(false);
    const history = useHistory();
    const { logout } = useAuth();

    // Função de logout
    async function handleLogout() {
        try {
            await logout();
            history.push('/'); // Redireciona para a página de login
        } catch (e){

        }
    }

    return (
        <nav>
            <header className="bg-grey">
                <div className="container">
                    <Link to={"/dashboard"}><img src={logo} alt="Company Portal | BRASA"/></Link>
                    <Tooltip title={<h5 style={{textAlign: "center"}}>For help, please contact us at tech@gobrasa.org</h5>}>
                        <a href="mailto:tech@gobrasa.org"><i className="far fa-question-circle text-white fa-4x"></i></a>
                    </Tooltip>
                    <div id="nav-icon" className={status ? "open" : ""} onClick={() => setStatus(!status)}>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
            </header>
            <div id="mainMenu" className={`bg-light-grey align-items-center ${status ? "d-flex" : "d-none"}`}>
                <div className="camada-menu"/>
                <ul className="mx-auto my-auto text-center">
                    <hr/>
                    <li><Link to={"/favorites"}>Favorites</Link></li>
                    <li><Link to={"/"} onClick={handleLogout}>Logout</Link></li>
                    <hr/>
                </ul>
            </div>
        </nav>
    );
}

export default Nav;