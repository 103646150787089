import React from "react";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthProvider } from "./services/authentication";
import { FilterContextProvider } from "./context/filterContext";
import { ConferenceContextProvider } from "./context/conferenceContext";

/*
App.js
Componente principal da Plataforma de Empresas.
Conexões externas:
    autenticação: usa função AuthProvider de "./services/authentication"
    contexto: usa função FilterContextProvider de "./context/filterContext"
    rotas: usa BrowserRouter, Switch e Route de "react-router-dom"
    rota protegida: usa ProtectedRoute de "./ProtectedRoute"
    páginas: usa Login e Dashboard de "./components/pages"
Tutoriais relevantes:
    BrowserRouter: https://v5.reactrouter.com/web/api/BrowserRouter
    Switch: https://v5.reactrouter.com/web/api/Switch
    Route: https://v5.reactrouter.com/web/api/Route
    ProtectedRoute: https://reactrouter.com/web/example/auth-workflow
*/

function App() {
    console.log(process.env.REACT_APP_CONF);
    return (
        <HttpsRedirect>
            <Router>
                <div>
                    <AuthProvider>
                        <ConferenceContextProvider>
                            <FilterContextProvider>
                                <Switch>
                                    <Route path="/" exact component={Login} />
                                    <ProtectedRoute path="/dashboard" exact component={Dashboard} />
                                    <ProtectedRoute path="/favorites" exact component={Dashboard} />
                                </Switch>
                            </FilterContextProvider>
                        </ConferenceContextProvider>
                    </AuthProvider>
                </div>
            </Router>
        </HttpsRedirect>
    );
}

export default App
