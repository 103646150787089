import axios from 'axios';

export async function addViewed(candidateId) {
  try {
    await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/viewed/${candidateId}`, {});
    return true;
  }
  catch (error) {
    console.log(error); // TODO improve error handling
    return false;
  }
}

export async function deleteViewed(candidateId) {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/viewed/${candidateId}`, {});
    return true;
  }
  catch (error) {
    console.log(error); // TODO improve error handling
    return false;
  }
}