import axios from 'axios';


/*
candidates.js
Serviço de candidatos da Plataforma de Empresas.
Possibilita a busca de candidatos por página, filtro e query. Esses campos são passados como parâmetros e são opcionais.
Dúvida: o que é o label?
Conexões externas:
    Nenhuma
Tutoriais relevantes:
    axios: https://axios-http.com/docs/intro
 */

function addConferencesToQuery(query, conferences) {
    query = query === '' ? '&' : '&' + query + '&';
    if (conferences.length > 0) {
        conferences.forEach(conference => { query += `conference=${conference.value}&` })
    }
    query = query.slice(0, -1);
    return query;
}

export async function getCandidates(page, conferences = [], filterName = '', query = '', label = '') {
    try {
        // Se não houver label, busca todos os candidatos
        if (label === '') {
            let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/candidates?page=${page}${addConferencesToQuery(query, conferences)}`,
                {},
                {
                    headers: {
                        'name': filterName
                    },
                }
            );

            return response.data;
        } else {
            let response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/pe2/candidates/label/${label}?page=${page}${addConferencesToQuery(query, conferences)}`,
                // Se houver label, busca os candidatos com o filtro.
                {
                    headers: {
                        'name': filterName
                    },
                }
            );
            return response.data;
        }
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}
