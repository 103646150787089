import React, { useState, useEffect } from "react";
import '../styles/Admin.css';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Box from "@material-ui/core/Box";
import { createComment, deleteComment, getComments } from '../services/comments';
import { createLabel, deleteLabel, getLabels, getAllLabels } from '../services/labels';
import { useAuth } from "../services/authentication";

import { Autocomplete, TextField, Chip } from '@mui/material';
import { Button } from "@material-ui/core";

/*
CandidateList.js
Lista de candidatos para a Plataforma de Empresas.
Conexões externas:
    stylesheet: "../styles/Admin.css"
    componentes: Tooltip, Snackbar, Alert, Popover, PopupState, Box, Chip, Autocomplete, TextField, Button
    serviços: comments, labels, authentication
    Usado no Dashboard.js
Tutorial relevante:
    Material-UI: https://mui.com/components/tooltips/  
*/

function CandidateList(props) {

    const [candidates, setCandidates] = useState([]);
    const [candidateIndex, setCandidateIndex] = useState(-1);
    const [messageEmail, setMessageEmail] = useState({
        status: true,
        message: ""
    })
    const [displayedCandidate, setDisplayedCandidate] = useState();
    const [linkedinError, setLinkedinError] = useState(false);
    const [commentsForSelectedCandidate, setCommentsForSelectedCandidate] = useState([]);
    const [goodToChangeComments, setGoodToChangeComments] = useState(false);
    const { currentUser } = useAuth();

    const [indexToLabel, setIndexToLabel] = useState(-1);
    const [showCandidate, setShowCandidate] = useState();
    const [labelsForSelectedCandidate, setLabelsForSelectedCandidate] = useState([]);
    const [holdLabel, setHoldLabel] = useState("");
    const [labelList, setLabelList] = useState([]);

    // this is for the comments modal
    const containerRef = React.useRef();

    // Hook para atualizar o candidato exibido. Renderiza baseado em mudanças no índice do candidato.
    useEffect(async () => {
        if (candidateIndex === -1) {
            setDisplayedCandidate(null);
        } else if (candidateIndex >= 0) {
            // await retrieveCVUrl();
            setDisplayedCandidate(candidates[candidateIndex]);
            setGoodToChangeComments(false);
            setCommentsForSelectedCandidate([]);
        }
    }, [candidateIndex]);

    // Hook para atualizar o candidato exibido. Renderiza baseado em mudanças no state indexToLabel.
    useEffect(async () => {
        if (indexToLabel === -1) {
            setShowCandidate(null);
        } else if (indexToLabel >= 0) {
            setShowCandidate(candidates[indexToLabel]);
            setLabelsForSelectedCandidate([]);
        }
    }, [indexToLabel]);

    useEffect(() => {
        setCandidates(props.candidates);
    }, [props.candidates])

    function handleArrowModal(next) {
        if ((next === 1) && candidateIndex < (candidates.length - 1)) setCandidateIndex(candidateIndex + 1);
        else if ((next === -1) && candidateIndex > 0) setCandidateIndex(candidateIndex - 1);
        else setCandidateIndex(-1);
    }

    function handleLabelsArrowModal(next) {
        if ((next === 1) && indexToLabel < (candidates.length - 1)) setIndexToLabel(indexToLabel + 1);
        else if ((next === -1) && indexToLabel > 0) setIndexToLabel(indexToLabel - 1);
        else setIndexToLabel(-1);
    }

    // COMMENTS

    useEffect(async () => {
        if (goodToChangeComments) {
            let comments = await getComments(displayedCandidate.candidateId);
            setCommentsForSelectedCandidate(comments);
        }
        setGoodToChangeComments(false);
    }, [goodToChangeComments]);

    // needs to add the comment, email do candidate, and companies domain
    const sendComments = async (candidateId, comment) => {
        let comments = await createComment(candidateId, comment);
        if (comments) {
            setCommentsForSelectedCandidate([...commentsForSelectedCandidate, {
                id: comments.comment_id,
                comment,
                recruiter: {
                    name: currentUser
                }
            }]);
        }
    }

    const deleteComments = async (commentId) => {
        const response = await deleteComment(commentId);
        if (response) {
            setCommentsForSelectedCandidate(commentsForSelectedCandidate.filter(comment => comment.id !== commentId));
        }
    }

    // LABELS

    useEffect(async () => {
        if (showCandidate) {
            const list = await getAllLabels();
            const labels = await getLabels(showCandidate.candidateId);
            setLabelsForSelectedCandidate(labels.error ? [] : labels);
            setLabelList(list.error ? [] : list);
        }
    }, [showCandidate]);

    const sendLabels = async (candidateId, label) => {
        console.log(label)
        let labels = await createLabel(candidateId, label);
        if (labels) {
            setLabelsForSelectedCandidate([...labelsForSelectedCandidate, {
                id: labels.label.id,
                name: label,
            }]);
        }
        props.handleNewLabel(label);
    }

    const deleteLabels = async (labelId) => {
        const response = await deleteLabel(labelId);
        if (response) {
            setLabelsForSelectedCandidate(labelsForSelectedCandidate.filter(label => label.id !== labelId));
        }
    }

    const handleProfileClick = async (index, candidateId) => {
        props.toggleViewed(candidateId, false);
        setCandidateIndex(index);
    }

    const handleLabelClick = async (index, candidateId) => {
        props.toggleViewed(candidateId, false);
        setIndexToLabel(index);
    }

    return (
        <div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            {/* <th scope="col">#</th> */}
                            <th scope="col">Name</th>
                            <th scope="col">Major</th>
                            <th scope="col">University</th>
                            <th scope="col" className="thin-column" colSpan="4"><i className="fas fa-cog" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            candidates && candidates.map(
                                (candidate, index) =>
                                    <tr key={candidate.candidateId} style={{ backgroundColor: (candidate.viewed ? "#e9ecef" : ""), borderCollapse: "collapse" }}>
                                        {/* <th scope="row">{index + 1}</th> */}
                                        <td>{candidate.firstName} {candidate.lastName}</td>
                                        <td>{candidate.major1}</td>
                                        <td>{candidate.university}</td>
                                        <td>
                                            <Tooltip title="Candidate's Labels">
                                                <span onClick={() => handleLabelClick(index, candidate.candidateId)} className="btn bg-dark"><i class='fas fa-user-tag text-white' /></span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title="Candidate's Profile">
                                                <span onClick={() => handleProfileClick(index, candidate.candidateId)} className="btn bg-dark-grey"><i className="fas fa-address-card text-white" /></span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title={candidate.favorite ? "Remove from Favorites" : "Add to Favorites"}>
                                                <span onClick={() => props.toggleFavorite(candidate.candidateId)} className="btn bg p-1">{candidate.favorite ?
                                                    <i className="fas fa-heart text-danger fa-lg" /> :
                                                    <i className="far fa-heart text-danger fa-lg" />} </span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title={candidate.viewed ? "Mark as Non-viewed" : "Mark as Viewed"}>
                                                <span onClick={() => props.toggleViewed(candidate.candidateId, true)} className="btn bg p-1">{candidate.viewed ?
                                                    <i className="fas fa-eye text-green fa-lg" /> :
                                                    <i className="far fa-eye-slash text-danger fa-lg" />} </span>
                                            </Tooltip>
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {
                displayedCandidate &&
                <div id="cv-modal" className="modal">
                    <div className="modal-dialog modal-lg w-200 h-100" >
                        <span id="arrow-left" className={"fas fa-chevron-left fa-3x text-white " + (candidateIndex > 0 ? "" : "opacity-0")} onClick={() => handleArrowModal(-1)}>
                        </span>
                        <div className="modal-content" style={{
                            width: "1000px !important",
                        }}>
                            <div className="body-max-width mb-3" style={{
                                paddingTop: "2rem",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                                <h2 className="modal-title font-weight-bold">{displayedCandidate.firstName} {displayedCandidate.lastName}</h2>
                                <button onClick={() => setCandidateIndex(-1)} type="button" className="close">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="modal-body body-max-width d-flex align-items-center justify-content-center">
                                {(!displayedCandidate.cv
                                    &&
                                    <h4 className="text-center">This candidate did not provide a CV</h4>
                                ) ||
                                    <iframe
                                        title={displayedCandidate.firstName + ' ' + displayedCandidate.lastName}
                                        id="cv-iframe"
                                        src={`${process.env.REACT_APP_BASE_API_URL}/api/pe2/getResume/${displayedCandidate.userId}?auth=${localStorage.getItem("token")}`}
                                        scrolling="no"
                                        width="100%"
                                        allowFullScreen
                                    />
                                }
                            </div>
                            <div className="modal-footer">
                                <Tooltip title={displayedCandidate.viewed ? "Mark as Unviewed" : "Mark as Viewed"}>
                                    <span onClick={() => props.toggleViewed(displayedCandidate.candidateId) && setDisplayedCandidate({ ...displayedCandidate, viewed: !displayedCandidate.viewed })} className="btn bg p-1">{displayedCandidate.viewed ?
                                        <i className="fas fa-eye text-green fa-lg" /> :
                                        <i className="far fa-eye-slash text-danger fa-lg" />} </span>
                                </Tooltip>
                                <Tooltip title={displayedCandidate.favorite ? "Remove from Favorites" : "Add to Favorites"}>
                                    <span onClick={() => props.toggleFavorite(displayedCandidate.candidateId) && setDisplayedCandidate({ ...displayedCandidate, favorite: !displayedCandidate.favorite })} className="align-bottom p-2">{displayedCandidate.favorite ?
                                        <i className="fas fa-heart text-danger fa-lg" /> :
                                        <i className="far fa-heart text-danger fa-lg" />} </span>
                                </Tooltip>
                                <Tooltip title="E-mail Candidate">
                                    <a rel="noopener noreferrer" target="_blank" href={"mailto:" + displayedCandidate.email} style={{ background: "#383838" }} className="btn m-2"><i className="fa fa-envelope text-white" /></a>
                                </Tooltip>
                                <PopupState variant="popover" popupId="comments-for-candidate">
                                    {(popupState) => (
                                        <Box ref={containerRef}>
                                            <Tooltip title="Comments">
                                                <a {...bindTrigger(popupState)} style={{ background: "#F3C515" }} className="btn m-2">
                                                    <i className="fa fa-comments text-white" />
                                                </a>
                                            </Tooltip>

                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                container={containerRef.current}
                                                PaperProps={{
                                                    style: { maxWidth: '500px', maxHeight: '800px', overflow: 'auto' },
                                                }}
                                            // container={this.AnchorEl.parentNode}
                                            >
                                                {(popupState.isOpen && goodToChangeComments === false) && setGoodToChangeComments(true)}
                                                <div id="box-comments">
                                                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                                        <Box gridColumn="span 12">
                                                            <h3 id="header-comments">Comments</h3>
                                                        </Box>
                                                        <Box gridColumn="span 12">
                                                            <Alert severity="info">Only your co-workers will be able to read your comments</Alert>
                                                        </Box>
                                                        <Box gridColumn="span 12">
                                                            {/* goes over the comments for this candidate here */}
                                                            {commentsForSelectedCandidate ?
                                                                <div className="comments">
                                                                    {commentsForSelectedCandidate.map(function (comment) {
                                                                        return (
                                                                            <div key={comment.id}>
                                                                                <div className="row">
                                                                                    <div className="col-8">
                                                                                        <p style={{ textAlign: 'left', fontWeight: 'bold', margin: 0 }}>{comment.recruiter.name}</p>
                                                                                    </div>
                                                                                    {comment.recruiter.name === currentUser &&
                                                                                        <>

                                                                                            <div className="col-2">
                                                                                                <button className="btn" onClick={() => deleteComments(comment.id)}><i className="fa fa-trash-alt" ></i></button>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p style={{ textAlign: 'left' }}>{comment.comment}</p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                                : <p>There are no comments for the candidate</p>
                                                            }
                                                        </Box>
                                                        <Box gridColumn="span 12">
                                                            <textarea placeholder="Write your comments here." className="form-control" id="input-comments" rows="3"></textarea>
                                                        </Box>
                                                        <Box gridColumn="span 12">
                                                            <button onClick={function () {
                                                                if (document.getElementById("input-comments").value === '') {
                                                                    return;
                                                                }
                                                                sendComments(displayedCandidate.candidateId, document.getElementById("input-comments").value);
                                                                document.getElementById("input-comments").value = '';
                                                            }} id="submit-comment-btn" type="submit" className="btn btn-dark" >Submit</button>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Popover>
                                        </Box>
                                    )}
                                </PopupState>
                                {displayedCandidate.linkedin === "" ?
                                    <div>
                                        <button onClick={() => setLinkedinError(true)} style={{ background: "#0e76a8" }} className="btn disabled p-8">
                                            <i aria-disabled={true} className="fab fa-linkedin-in text-white" /></button>
                                    </div>
                                    :
                                    <Tooltip title="Open LinkedIn Profile">
                                        <a rel="noopener noreferrer" target="_blank" href={displayedCandidate.linkedin} style={{ background: "#0e76a8" }} className="btn"><i className="fab fa-linkedin-in text-white" /></a>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                        <span id="arrow-right" className={"fas fa-chevron-right fa-3x text-white " + (candidateIndex < (candidates.length - 1) ? "" : "opacity-0")} onClick={() => handleArrowModal(1)}>
                        </span>
                        <span id="arrow-right" className={"fas fa-chevron-right fa-3x text-white " + (indexToLabel < (indexToLabel.length - 1) ? "" : "opacity-0")} onClick={() => handleLabelsArrowModal(1)}>
                        </span>
                    </div>
                </div>
            }

            <Snackbar open={linkedinError} autoHideDuration={2000} onClose={() => setLinkedinError(false)}>
                <Alert onClose={() => setLinkedinError(false)} severity="error">
                    This candidate has not provided a LinkedIn profile.
                </Alert>
            </Snackbar>
            <Snackbar open={messageEmail.message !== ""} autoHideDuration={5000} onClose={() => setMessageEmail({
                ...messageEmail,
                message: ""
            })}>
                <Alert onClose={() => setMessageEmail({
                    ...messageEmail,
                    message: ""
                })} severity={messageEmail.status ? "success" : "error"}>
                    {messageEmail.message}
                </Alert>
            </Snackbar>
            {
                showCandidate &&
                <div id="labels-modal" className="modal">
                    <div className="modal-dialog modal-lg w-200 h-100">
                        <span id="arrow-left" className={"fas fa-chevron-left fa-3x text-white " + (indexToLabel > 0 ? "" : "opacity-0")} onClick={() => handleLabelsArrowModal(-1)}>
                        </span>
                        <div className="modal-content" style={{
                            width: "1000px !important",
                            minHeight: "50%",
                        }}>
                            <div className="body-max-width mb-3" style={{
                                padding: "2rem",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                                <h2 className="modal-title font-weight-bold">{showCandidate.firstName} {showCandidate.lastName}</h2>
                                <button onClick={() => setIndexToLabel(-1)} type="button" className="close">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <Box sx={{ display: 'flex' }}>
                                <Autocomplete
                                    sx={{ width: '100%', m: 2 }}
                                    freeSolo
                                    autoSelect
                                    id="tags-outlined"
                                    onChange={(event, newValue) => { setHoldLabel(newValue) }}
                                    options={labelList ? labelList : []}
                                    getOptionLabel={(option) => {
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Labels"
                                            placeholder="Labels"
                                        />
                                    )}
                                />
                                <Button variant='outlined' onClick={() => sendLabels(showCandidate.candidateId, holdLabel)}>
                                    Add Label
                                </Button>
                            </Box>
                            <Box sx={{ m: 2 }}>
                                <h4 className="modal-title font-weight-bold">Labels desse candidato:</h4>
                                {labelsForSelectedCandidate ? labelsForSelectedCandidate.map((label) => {
                                    return (
                                        <Chip
                                            label={label.name}
                                            onDelete={() => deleteLabels(label.id)}
                                            sx={{ m: 1 }}
                                        />
                                    );
                                })
                                    :
                                    <h5 className="modal-title">Esse candidato não possui labels</h5>
                                }
                            </Box>
                        </div>
                        <span id="arrow-right" className={"fas fa-chevron-right fa-3x text-white " + (indexToLabel < (candidates.length - 1) ? "" : "opacity-0")} onClick={() => handleLabelsArrowModal(1)}>
                        </span>
                    </div>
                </div>
            }
        </div >
    );
}

export default CandidateList;
