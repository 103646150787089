import React, {useRef, useState} from "react";
import logo from "../../images/brasa.png";
import {useHistory} from 'react-router-dom';
// import Tilt from 'react-tilt'
import "../../styles/Login.css";
import {useAuth} from "../../services/authentication";

/*
Login.js
Componente de Login da Plataforma de Empresas
Conexões externas:
    recurso: imagem de fundo em "../../images/brasa.png"
    stylesheet: "../../styles/Login.css" e bootstrap
    autenticação: usa função useAuth de "../../services/authentication"
Tutoriais relevantes:
    useHistory: https://v5.reactrouter.com/web/api/history
    Boostrap: https://getbootstrap.com/docs/4.1/getting-started/introduction/
*/

function Login() {
    // Hooks
    const [loginStatusError, setLoginStatusError] = useState(false); // Estado de erro de login
    const [loading, setLoading] = useState(false); // Estado de carregamento
    const emailRef = useRef(); // Referência ao campo de email
    const passwordRef = useRef(); // Referência ao campo de senha
    const { login } = useAuth(); // Função de login
    const history = useHistory(); // Histórico de navegação

    /**
     * Função de login para a plataforma de empresas
     * @param {*} e evento de formulário
     */
    async function handleLogin(e){
        e.preventDefault();
        try{
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);

            setTimeout(() => {
                history.push('/dashboard');
            }, 400);
            // history.push('/dashboard');
        } catch(err) {
            setLoginStatusError(true);
        }
        setLoading(false);
    }

    return (
        <main>
            <div className='background-brasa'>
                <div className="box">
                    <div className="text-instruction text-center">
                        <p className="text-center">
                            {/* <Tilt className="Tilt" options={{ max : 45 }}> */}
                                <img alt="BRASA" className="Tilt-inner" width="40%" src={logo}/>
                            {/* </Tilt> */}
                        </p>
                        <h1>Company Portal</h1>
                    </div>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" ref={emailRef} required/>
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" ref={passwordRef} required/>
                        </div>

                        <button disabled={loading} className="btn bg-grey text-white w-100">Login</button>
                    </form>
                    <div style={loginStatusError ? {display: "block"} : {display: "none"}}  className="mt-3 alert alert-danger" role="alert">
                        Incorrect email address or password.
                        <button onClick={() => setLoginStatusError(false)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Login;
