import React, { useState, useEffect, useMemo } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import "../../styles/Admin.css";

import Nav from "../Nav";
import Loader from "react-loader-spinner";
import Pagination from "@material-ui/lab/Pagination";
import { Autocomplete, Box, TextField, Chip } from "@mui/material";

import CandidateList from "../CandidateList";
import Filters from "../Filters";

import { useAuth } from "../../services/authentication";
import ConferenceContext from "../../context/conferenceContext";

import {
  addFavorite,
  getFavorites,
  deleteFavorite,
} from "../../services/favorites";
import { getCandidates } from "../../services/candidates";
import { getAllLabels } from "../../services/labels";
import { addViewed, deleteViewed } from "../../services/viewed";

/*
Dashboard.js
Componente do Dashboard da Plataforma de Empresas.
Mostra os candidatos e favoritos da empresa.
Conexão com a API é feita através de funções de favoritos, candidatos e labels.
Conexões externas:
    recurso: imagem de fundo em "../../images/brasa.png"
    stylesheet: "../../styles/Login.css" e bootstrap
    autenticação: usa função useAuth de "../../services/authentication"
    favoritos: usa funções de favoritos de "../../services/favorites"
    candidatos: usa funções de candidatos de "../../services/candidates"
    labels: usa funções de labels de "../../services/labels"
Tutoriais relevantes:
    Boostrap: https://getbootstrap.com/docs/4.1/getting-started/introduction/
    React Promise Tracker: https://www.npmjs.com/package/react-promise-tracker
    UseMemo: https://reactjs.org/docs/hooks-reference.html#usememo
*/
function Dashboard() {
  /* context */

  const { currentUser } = useAuth();
  const useConference = React.useContext(ConferenceContext);
  const conferenceOptions = [...useConference.conferences];

  /* states */

  const [fixedConference, setFixedConference] = useState([]);
  const [allCandidates, setAllCandidates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [label, setLabel] = useState(null);
  const [labelOptions, setLabelOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [conferences, setConferences] = useState([...conferenceOptions]);
  // hook para que filtros possam atualizar a lista de candidatos mesmo quando a
  // query não muda
  const [filterChange, setFilterChange] = useState(false);

  /* methods */

  const isFavorites = useMemo(
    () => window.location.pathname === "/favorites",
    [window.location.pathname]
  );
  useEffect(() => {
    getAllLabels().then((data) => {
      if (!data.error) setLabelOptions(data.map((label) => label));
    });

    if (isFavorites) {
      bringFavs(
        currentPage,
        conferences.length > 0 ? conferences : conferenceOptions
      );
    } else {
      if (!label) {
        bringThemAll(
          currentPage,
          conferences.length > 0 ? conferences : conferenceOptions
        );
      } else {
        bringByLabel(
          currentPage,
          conferences.length > 0 ? conferences : conferenceOptions
        );
      }
    }
  }, [isFavorites, currentPage, query, label, conferences, filterChange]);

  // Loading indicator para a lista de candidatos
  const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
      promiseInProgress && (
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader type="ThreeDots" color="#383838" height="100" width="100" />
        </div>
      )
    );
  };

  // Função para trazer todos os candidatos da API
  const bringThemAll = async (page, conferences) => {
    trackPromise(
      getCandidates(page, conferences, "", query).then((res) => {
        if (res !== undefined) {
          setAllCandidates(res.users);
          setPageCount(res.totalPages);
          setTotalCount(res.totalItems);
          setCurrentPage(
            res.currentPage > res.totalPages ? 1 : Number(res.currentPage)
          );
        }
      })
    );
  };

  // Função para trazer candidatos por label
  const bringByLabel = async (page, conferences) => {
    trackPromise(
      getCandidates(page, conferences, "", query, label).then((res) => {
        if (res !== undefined) {
          setAllCandidates(res.users);
          setPageCount(res.totalPages);
          setTotalCount(res.totalItems);
          setCurrentPage(
            res.currentPage > res.totalPages ? 1 : res.currentPage
          );
        }
      })
    );
  };

  // Função para trazer candidatos favoritos da API
  const bringFavs = async (page, conferences) => {
    trackPromise(
      getFavorites(page, conferences).then((res) => {
        if (res !== undefined) {
          setAllCandidates(res.users);
          setPageCount(res.totalPages);
          setTotalCount(res.totalItems);
          setCurrentPage(
            res.currentPage > res.totalPages ? 1 : res.currentPage
          );
        }
      })
    );
  };

  const applyFilter = (filterQuery) => {
    setQuery(filterQuery);
    // quando a query não muda, o setFilterChange triggers a api call
    setFilterChange(!filterChange);
  };

  // Função para adicionar ou remover um candidato dos favoritos
  async function toggleFavorite(candidateId) {
    const updateCandidate = allCandidates.find(
      (candidate) => candidate.candidateId === candidateId
    );
    if (updateCandidate.favorite) {
      await deleteFavorite(updateCandidate.candidateId);
      updateCandidate.favorite = false;
    } else {
      await addFavorite(updateCandidate.candidateId);
      updateCandidate.favorite = true;
    }
    const candidates = allCandidates.map((candidate) => {
      if (candidate.candidateId === candidateId) {
        return updateCandidate;
      }
      return candidate;
    });
    setAllCandidates(candidates);
  }

  async function toggleViewed(candidateId, allowUnview) {
    const updateCandidate = allCandidates.find(
      (candidate) => candidate.candidateId === candidateId
    );
    if (updateCandidate.viewed) {
      if (allowUnview) {
        await deleteViewed(updateCandidate.candidateId);
        updateCandidate.viewed = false;
      }
    } else {
      await addViewed(updateCandidate.candidateId);
      updateCandidate.viewed = true;
    }
    const candidates = allCandidates.map((candidate) => {
      if (candidate.candidateId === candidateId) {
        return updateCandidate;
      }
      return candidate;
    });
    setAllCandidates(candidates);
  }

  function handleAutocompleteConferences(conferences) {
    if (conferences.length === 1) {
      setFixedConference(conferences);
    } else {
      setFixedConference([]);
    }
    setConferences(conferences);
  }

  function handleNewLabel(label) {
    setLabelOptions([...labelOptions, label]);
  }

  return (
    <main>
      <Nav />
      <div className="container main">
        <div className="row align-items-center">
          <div className="col-12">
            <h1>Hello {currentUser},</h1>
          </div>
          <div className="col-12 col-md-8 mb-3">
            <div className="card">
              <div className="card-body bg-dark-yellow">
                <div className="row">
                  <div className="col-12 col-md-3 text-center d-flex align-items-center">
                    <div className="number-circle">{totalCount}</div>
                  </div>
                  <div className="col-12 col-md-9 text-center d-flex align-items-center">
                    <p className="desc-stats">
                      Number of {isFavorites ? "Favorites" : "Candidates"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3">
            {(isFavorites && (
              <Link to="/dashboard" className="statis-link">
                Return <i className="fa fa-arrow-right" />
              </Link>
            )) || (
                <Link to="/favorites" className="statis-link">
                  My Favorites
                  <i className="far fa-heart" />
                </Link>
              )}
          </div>
        </div>
        <Filters applyFilter={applyFilter} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Select a label:</h2>
          <Autocomplete
            id="label-selection"
            options={labelOptions ? labelOptions : []}
            getOptionLabel={(option) => option}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Label" variant="standard" />
            )}
            onChange={(e, v) => {
              setLabel(v);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "0.25rem",
          }}
        >
          <h2>Select a conference:</h2>
          <Autocomplete
            multiple
            id="conference-selection"
            options={conferenceOptions}
            value={conferences}
            getOptionLabel={(option) => option.label}
            style={{ width: 300 }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  disabled={fixedConference.includes(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label="Conference" variant="standard" />
            )}
            onChange={(e, v) => {
              handleAutocompleteConferences(v);
            }}
          />
        </Box>
        <hr />
        <LoadingIndicator />
        <div className="card card-body mb-5">
          <CandidateList
            candidates={allCandidates}
            toggleFavorite={toggleFavorite}
            toggleViewed={toggleViewed}
            handleNewLabel={handleNewLabel}
          />
          <div className={"text-center justify-content-center mt-5 d-flex"}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
            />
          </div>
        </div>
      </div>
      <footer className="bg-grey text-center text-lg-start">
        <div className="text-center p-3 text-white">
          BRASA © {new Date().getFullYear()} Copyright
        </div>
      </footer>
    </main>
  );
}

export default Dashboard;
